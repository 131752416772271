import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(51.872877,-2)">
      <g transform="translate(11,5)">
        <path
          d="m -21.206211,35.63541 h 0.270834 l 6.208338,24.395851 h 6.354171 l -9.02084,-30.062522 h -6.979172 l -9.000006,30.062522 h 5.875004 z m 4.833337,12.458343 h -9.562507 l -0.916667,4.395836 h 11.583342 z"
          fill="currentColor"
        />
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="0,67 39,90 78,68 78,23 39,0 0,22 "
          transform="translate(-59.872877)"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
